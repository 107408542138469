<template>
  <div>
      <v-row no-gutters  v-if="valueLine.chartId && valueLine.type=='echart'">
        <v-col style="max-width: 30px" class="mt-1">
        <checkbox
          :fieldAttributes="{}"
          :fieldAttrInput="{name:'Preview'}"
          field="preview"
          :value="isSelected"
          v-model="checked"
          @input="updateCheck"
          id="dashboard_chart_preview"
        ></checkbox>
          </v-col>
          <v-col style="margin-top: 14px;" class="ml-2">
              <p class="subtitle-2">{{language.componentLabel.chartPreview}}</p>
          </v-col>
      </v-row>
   </div>
</template>
<script>
import checkbox from "@/commonComponents/checkbox.vue";
export default {
  components: {
    checkbox,
  },
  props: ["result","valueLine"],
  data: function () {
    return {
      checked: false,
    };
  },
  created() {
    this.checkBoxcheck();
  },
  watch: {
    valueLine() {
      this.checkBoxcheck();
    },
  },
  methods: {
    checkBoxcheck() {
      if (this.valueLine.preview) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    updateCheck(value) {
      let check = true;
      if (value == 0) {
        this.checked = false;
        check = false;
      }
      this.$set(this.valueLine, "preview", check);
    },
    isSelected(value) {
      if (value) {
        return 1;
      } else {
        return 0;
      }
    },
  },
};
</script>